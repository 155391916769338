import { GitHubLogoIcon } from "@radix-ui/react-icons";
import { buttonVariants } from "./ui/button";

import { HeroCards } from "./HeroCards";

export function Hero() {
  return (
    <section className="container grid lg:grid-cols-2 place-items-center py-20 md:py-32 gap-10">
      <div className="text-center lg:text-start space-y-6">
        <main className="text-5xl md:text-6xl font-bold">
          <h1 className="inline">
            <span className="inline bg-gradient-to-r from-[#61DAFB] via-[#1fc0f1] to-[#03a3d7] text-transparent bg-clip-text">
              Engineer Theory
            </span>
            {" "}
            strives for
          </h1>
          {" "}
          <h2 className="inline">
            <span className="inline bg-gradient-to-r from-[#9333EA] via-[#A855F7] to-[#7E22CE] text-transparent bg-clip-text">
              Engineering
            </span>
            {" "}
            and
            {" "}
            <span className="inline bg-gradient-to-r from-[#22C55E] via-[#16A34A] to-[#15803D] text-transparent bg-clip-text">Operational</span>
            {" "}
            excellence
          </h2>
        </main>

        <p className="text-xl text-muted-foreground md:w-10/12 mx-auto lg:mx-0">
          Build your microservices architecture effortlessly with industry best practices.
        </p>

        <div className="space-y-4 md:space-y-0 md:space-x-4">
          <a
            href="mailto:engineertheory.com@gmail.com?subject=Engineering%20Excellence%20Consultation&body=Hi%20Engineer%20Theory%20Team%2C%0A%0AI%20am%20interested%20in%20learning%20more%20about%20your%20engineering%20consulting%20services.%20Could%20you%20please%20provide%20information%20about%3A%0A%0A-%20Your%20microservices%20architecture%20expertise%0A-%20Operational%20excellence%20consulting%0A-%20Available%20consultation%20time%20slots%0A%0AThank%20you%2C%0A"
            className={`${buttonVariants()} w-full md:w-1/3`}
          >
            Get Started
          </a>

          <a
            href="https://github.com/EngineerTheory/"
            target="_blank"
            rel="noreferrer noopener"
            className={`w-full md:w-1/3 ${buttonVariants({
              variant: "outline",
            })}`}
          >
            Github
            <GitHubLogoIcon className="ml-2 w-5 h-5" />
          </a>
        </div>
      </div>

      {/* Hero cards sections */}
      <div className="z-10">
        <HeroCards />
      </div>

      {/* Shadow effect */}
      <div className="shadow"></div>
    </section>
  );
}
